@import './variables/colors.scss';
@import './variables/settings.scss';
@import './variables/mixins.scss';

.gallery-container{
    // cover image
    .image{
        height: 420px;
        width: 250px;
        margin: 0 10px 20px 0;
        background-repeat: no-repeat;  
        box-shadow: 0 0 3px rgb(182, 182, 182);
        background-size: cover;
        transition: all .1s;
        border-radius: 4px;
        &.item-mounted {
            margin-top: 40px;
            height: 250px;
            border-radius: 50%;
        }
    }
    button{
        @include accent-button;
    }
    .galNode{ 
        color: #444;
        display: flex; 
        flex-wrap: wrap;
        margin: 0 auto; 
        padding-bottom: 46px;
        padding-top: 56px;
        position: relative;
        border-bottom: 2px solid rgb(219, 219, 219);
        .info{
            margin-left: 40px;
            flex: 1;
            .desc{
                line-height: 1.9rem;
                max-width: 760px;
                margin-bottom: 40px;
            }  
            h3{
                color: rgb(58, 58, 58);  
                font-size: 1.8rem;  
            }
            .cloud{ 
                display: flex;
                flex-wrap: wrap;
                margin-top: 22px;
                width: 100%;
                & p{ 
                    background-color: #c3c3c3;
                    padding: 2px 10px;
                    border-radius: 5px; 
                    margin-bottom: 0;
                    margin-right: 6px;
                    color: #fff;
                }
            }
            i{
                padding-top: 12px;
                display: block;
                color: gray;
                max-width: 760px;
                line-height: 1.9rem;
            } 
            a{
                color: $accent-blue;
                display: inline-block;
                padding: 2px 20px; 
                border: 2px solid $accent-blue;
                font-weight: bold;
                margin-right: 14px;
                margin-bottom: 10px;
                border-radius: 5px;
                &:hover{ color: gray; border-color: gray;}
            }
        } 

        
        @media screen and (max-width: $break-small) {  
            &{
                width: 100%;
                display: block;
                text-align: center;
                .vid{
                    max-width: 96%;
                }
                .info{
                    margin: 0 auto;
                    width: 96%;
                    h3{
                        width: 100%;
                        text-align: center
                    }
                    .cloud{ 
                        justify-content: center;
                    }
                }
                .image{ 
                    margin: 0 auto 20px auto;
                }
            }
            .top-bar-title {
                h2 {
                    font-size: 2rem;
                }
            }
          } // End Media
    }  
    
    .home-title {
        max-height: 500px;
        transition: all .1s;
        overflow: hidden;
        margin: 0;
        padding: 0;
        &.item-mounted {
            max-height: 0;
        }
    }

    .top-bar-title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        text-align: left;
        margin-bottom: 30px;
        h2 {
            margin: 0;
            font-size: 3rem;
            margin-left: -50px;
            opacity: 0;
            background-color: $main-body-color;
            transition: all .3s;
        }
        button {
            margin: 0;
            opacity: 0;
            transition: all .3s;
            height: 32px;
            width: 45px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &.item-mounted {
            h2, button {
                opacity: 1;
            }
            h2 {
                margin-left: 20px;
            }
        }
    }
}

.img-container {
    max-height: 0px;
    transition: max-height .4s;
    overflow: hidden;
    margin-top: 0;
    margin: 0 auto;
    &.item-mounted {
        margin-top: 20px;
        max-height: 12000px;
    }
}

.float-action {
    position: fixed;
    bottom: 20px;
    width: 95%;
    max-width: 1250px;
    margin: 0 auto;
    button {
        @include accent-button;
    }
}