.side-bar {
  background-color: #fff;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.4);
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 450px;
  z-index: 4;
}
.side-bar .logo {
  height: 60px;
  padding: 0px 20px 0px 80px;
  margin-bottom: 20px;
  border-bottom: 4px solid #db0f56;
}
.side-bar .logo svg {
  width: 60%;
  margin-top: -7px;
}
.side-bar button {
  font-size: 16px;
  position: absolute;
  padding: 0;
  width: 60px;
  height: 60px;
  padding-top: 2px;
  background-color: #db0f56;
  color: #fff;
  transition: background-color 0.3s;
}
.side-bar button:hover {
  color: #fff;
  background-color: #414141;
}
.side-bar .cont {
  border-top: 4px solid #db0f56;
  padding-top: 21px;
}
.side-bar .cont span, .side-bar .cont a {
  font-size: 1.2em;
  margin-left: 10px;
}
.side-bar .nav a {
  display: block;
  padding: 22px 25px;
  font-size: 1.2rem;
  transition: all 0.1s;
}
.side-bar .nav a:hover {
  border-left: 8px solid #db0f56;
  padding: 22px 0 22px 35px;
  background-color: #fff4f8;
  color: #db0f56;
}
.side-bar .nav a.current-page {
  border-left: 4px solid #db0f56;
}
@media screen and (max-width: 750px) {
  .side-bar {
    max-width: 100%;
  }
}