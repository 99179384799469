.gallery-container .image {
  height: 420px;
  width: 250px;
  margin: 0 10px 20px 0;
  background-repeat: no-repeat;
  box-shadow: 0 0 3px #b6b6b6;
  background-size: cover;
  transition: all 0.1s;
  border-radius: 4px;
}
.gallery-container .image.item-mounted {
  margin-top: 40px;
  height: 250px;
  border-radius: 50%;
}
.gallery-container button {
  margin-top: 40px;
  background-color: #db0f56;
  color: #fff;
  padding: 8px 20px;
  right: 0;
  bottom: 0;
  text-align: center;
  border-radius: 5px;
  margin-left: auto;
}
.gallery-container button:hover {
  background-color: gray;
}
.gallery-container .galNode {
  color: #444;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  padding-bottom: 46px;
  padding-top: 56px;
  position: relative;
  border-bottom: 2px solid #dbdbdb;
}
.gallery-container .galNode .info {
  margin-left: 40px;
  flex: 1;
}
.gallery-container .galNode .info .desc {
  line-height: 1.9rem;
  max-width: 760px;
  margin-bottom: 40px;
}
.gallery-container .galNode .info h3 {
  color: #3a3a3a;
  font-size: 1.8rem;
}
.gallery-container .galNode .info .cloud {
  display: flex;
  flex-wrap: wrap;
  margin-top: 22px;
  width: 100%;
}
.gallery-container .galNode .info .cloud p {
  background-color: #c3c3c3;
  padding: 2px 10px;
  border-radius: 5px;
  margin-bottom: 0;
  margin-right: 6px;
  color: #fff;
}
.gallery-container .galNode .info i {
  padding-top: 12px;
  display: block;
  color: gray;
  max-width: 760px;
  line-height: 1.9rem;
}
.gallery-container .galNode .info a {
  color: #1dace3;
  display: inline-block;
  padding: 2px 20px;
  border: 2px solid #1dace3;
  font-weight: bold;
  margin-right: 14px;
  margin-bottom: 10px;
  border-radius: 5px;
}
.gallery-container .galNode .info a:hover {
  color: gray;
  border-color: gray;
}
@media screen and (max-width: 750px) {
  .gallery-container .galNode {
    width: 100%;
    display: block;
    text-align: center;
  }
  .gallery-container .galNode .vid {
    max-width: 96%;
  }
  .gallery-container .galNode .info {
    margin: 0 auto;
    width: 96%;
  }
  .gallery-container .galNode .info h3 {
    width: 100%;
    text-align: center;
  }
  .gallery-container .galNode .info .cloud {
    justify-content: center;
  }
  .gallery-container .galNode .image {
    margin: 0 auto 20px auto;
  }
  .gallery-container .galNode .top-bar-title h2 {
    font-size: 2rem;
  }
}
.gallery-container .home-title {
  max-height: 500px;
  transition: all 0.1s;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.gallery-container .home-title.item-mounted {
  max-height: 0;
}
.gallery-container .top-bar-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  margin-bottom: 30px;
}
.gallery-container .top-bar-title h2 {
  margin: 0;
  font-size: 3rem;
  margin-left: -50px;
  opacity: 0;
  background-color: #f9f9f9;
  transition: all 0.3s;
}
.gallery-container .top-bar-title button {
  margin: 0;
  opacity: 0;
  transition: all 0.3s;
  height: 32px;
  width: 45px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.gallery-container .top-bar-title.item-mounted h2, .gallery-container .top-bar-title.item-mounted button {
  opacity: 1;
}
.gallery-container .top-bar-title.item-mounted h2 {
  margin-left: 20px;
}

.img-container {
  max-height: 0px;
  transition: max-height 0.4s;
  overflow: hidden;
  margin-top: 0;
  margin: 0 auto;
}
.img-container.item-mounted {
  margin-top: 20px;
  max-height: 12000px;
}

.float-action {
  position: fixed;
  bottom: 20px;
  width: 95%;
  max-width: 1250px;
  margin: 0 auto;
}
.float-action button {
  margin-top: 40px;
  background-color: #db0f56;
  color: #fff;
  padding: 8px 20px;
  right: 0;
  bottom: 0;
  text-align: center;
  border-radius: 5px;
  margin-left: auto;
}
.float-action button:hover {
  background-color: gray;
}