h1, h2 {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 2rem;
  color: #db0f56;
}

a {
  text-decoration: none;
  color: inherit;
}

.t-small {
  font-size: 0.9rem;
}

.t-large {
  font-size: 1.2rem;
}

.t-light {
  color: #646464;
}

.t-space {
  line-height: 1.9rem;
}

.t-blue {
  color: #1dace3;
}

button {
  border: none;
  background-color: transparent;
  font-weight: bold;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
}

.button-form {
  background-color: #db0f56;
  padding: 10px 20px;
  color: #fff;
  border-radius: 4px;
  letter-spacing: 0.1rem;
  margin-top: 10px;
}
.button-form:hover {
  background-color: #4b4b4b;
}

label {
  font-weight: bold;
  color: #4b4b4b;
  font-size: 1.02rem;
}

input[type=text], textarea {
  padding: 15px 10px;
  display: block;
  margin: 10px 0 23px 0;
  width: calc(100% - 20px);
  border: none;
  background-color: #ffffff;
  box-shadow: 0 0 0 2px #1dace3;
  transition: all 0.3s;
  border-radius: 5px;
  color: #303030;
  transition: all 0.3s;
}
input[type=text]::placeholder, textarea::placeholder {
  color: #a0a0a0;
}
input[type=text]:focus, textarea:focus {
  padding: 15px 15px;
  width: calc(100% - 30px);
  box-shadow: 0 0 0px 1px #db0f56;
  border-color: #db0f56;
  background-color: #f3f3f3;
}

body {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
}

ul {
  list-style-type: none;
  list-style-position: inside;
  margin: 0;
  padding: 0;
}

.content {
  width: 95%;
  max-width: 1200px;
  min-width: 250px;
  margin: 0 auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.j-start {
  justify-content: flex-start;
}

.a-center {
  align-items: center;
}

.p-relative {
  position: relative;
}

.w-0 {
  width: calc(0% - 18px);
}
@media screen and (max-width: 750px) {
  .w-0 {
    width: calc(96% - 18px);
  }
}

.p-0 {
  padding: 0px;
}

.pt-0 {
  padding-top: 0px;
}

.pr-0 {
  padding-right: 0px;
}

.pl-0 {
  padding-left: 0px;
}

.pb-0 {
  padding-bottom: 0px;
}

.m-0 {
  margin: 0px;
}

.mt-0 {
  margin-top: 0px;
}

.mb-0 {
  margin-bottom: 0px;
}

.br-0 {
  border-radius: 0px;
}

.w-5 {
  width: calc(5% - 18px);
}
@media screen and (max-width: 750px) {
  .w-5 {
    width: calc(96% - 18px);
  }
}

.p-5 {
  padding: 5px;
}

.pt-5 {
  padding-top: 5px;
}

.pr-5 {
  padding-right: 5px;
}

.pl-5 {
  padding-left: 5px;
}

.pb-5 {
  padding-bottom: 5px;
}

.m-5 {
  margin: 5px;
}

.mt-5 {
  margin-top: 5px;
}

.mb-5 {
  margin-bottom: 5px;
}

.br-1 {
  border-radius: 1px;
}

.w-10 {
  width: calc(10% - 18px);
}
@media screen and (max-width: 750px) {
  .w-10 {
    width: calc(96% - 18px);
  }
}

.p-10 {
  padding: 10px;
}

.pt-10 {
  padding-top: 10px;
}

.pr-10 {
  padding-right: 10px;
}

.pl-10 {
  padding-left: 10px;
}

.pb-10 {
  padding-bottom: 10px;
}

.m-10 {
  margin: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.br-2 {
  border-radius: 2px;
}

.w-15 {
  width: calc(15% - 18px);
}
@media screen and (max-width: 750px) {
  .w-15 {
    width: calc(96% - 18px);
  }
}

.p-15 {
  padding: 15px;
}

.pt-15 {
  padding-top: 15px;
}

.pr-15 {
  padding-right: 15px;
}

.pl-15 {
  padding-left: 15px;
}

.pb-15 {
  padding-bottom: 15px;
}

.m-15 {
  margin: 15px;
}

.mt-15 {
  margin-top: 15px;
}

.mb-15 {
  margin-bottom: 15px;
}

.br-3 {
  border-radius: 3px;
}

.w-20 {
  width: calc(20% - 18px);
}
@media screen and (max-width: 750px) {
  .w-20 {
    width: calc(96% - 18px);
  }
}

.p-20 {
  padding: 20px;
}

.pt-20 {
  padding-top: 20px;
}

.pr-20 {
  padding-right: 20px;
}

.pl-20 {
  padding-left: 20px;
}

.pb-20 {
  padding-bottom: 20px;
}

.m-20 {
  margin: 20px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.br-4 {
  border-radius: 4px;
}

.w-25 {
  width: calc(25% - 18px);
}
@media screen and (max-width: 750px) {
  .w-25 {
    width: calc(96% - 18px);
  }
}

.p-25 {
  padding: 25px;
}

.pt-25 {
  padding-top: 25px;
}

.pr-25 {
  padding-right: 25px;
}

.pl-25 {
  padding-left: 25px;
}

.pb-25 {
  padding-bottom: 25px;
}

.m-25 {
  margin: 25px;
}

.mt-25 {
  margin-top: 25px;
}

.mb-25 {
  margin-bottom: 25px;
}

.br-5 {
  border-radius: 5px;
}

.w-30 {
  width: calc(30% - 18px);
}
@media screen and (max-width: 750px) {
  .w-30 {
    width: calc(96% - 18px);
  }
}

.p-30 {
  padding: 30px;
}

.pt-30 {
  padding-top: 30px;
}

.pr-30 {
  padding-right: 30px;
}

.pl-30 {
  padding-left: 30px;
}

.pb-30 {
  padding-bottom: 30px;
}

.m-30 {
  margin: 30px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-30 {
  margin-bottom: 30px;
}

.br-6 {
  border-radius: 6px;
}

.w-35 {
  width: calc(35% - 18px);
}
@media screen and (max-width: 750px) {
  .w-35 {
    width: calc(96% - 18px);
  }
}

.p-35 {
  padding: 35px;
}

.pt-35 {
  padding-top: 35px;
}

.pr-35 {
  padding-right: 35px;
}

.pl-35 {
  padding-left: 35px;
}

.pb-35 {
  padding-bottom: 35px;
}

.m-35 {
  margin: 35px;
}

.mt-35 {
  margin-top: 35px;
}

.mb-35 {
  margin-bottom: 35px;
}

.br-7 {
  border-radius: 7px;
}

.w-40 {
  width: calc(40% - 18px);
}
@media screen and (max-width: 750px) {
  .w-40 {
    width: calc(96% - 18px);
  }
}

.p-40 {
  padding: 40px;
}

.pt-40 {
  padding-top: 40px;
}

.pr-40 {
  padding-right: 40px;
}

.pl-40 {
  padding-left: 40px;
}

.pb-40 {
  padding-bottom: 40px;
}

.m-40 {
  margin: 40px;
}

.mt-40 {
  margin-top: 40px;
}

.mb-40 {
  margin-bottom: 40px;
}

.br-8 {
  border-radius: 8px;
}

.w-45 {
  width: calc(45% - 18px);
}
@media screen and (max-width: 750px) {
  .w-45 {
    width: calc(96% - 18px);
  }
}

.p-45 {
  padding: 45px;
}

.pt-45 {
  padding-top: 45px;
}

.pr-45 {
  padding-right: 45px;
}

.pl-45 {
  padding-left: 45px;
}

.pb-45 {
  padding-bottom: 45px;
}

.m-45 {
  margin: 45px;
}

.mt-45 {
  margin-top: 45px;
}

.mb-45 {
  margin-bottom: 45px;
}

.br-9 {
  border-radius: 9px;
}

.w-50 {
  width: calc(50% - 18px);
}
@media screen and (max-width: 750px) {
  .w-50 {
    width: calc(96% - 18px);
  }
}

.p-50 {
  padding: 50px;
}

.pt-50 {
  padding-top: 50px;
}

.pr-50 {
  padding-right: 50px;
}

.pl-50 {
  padding-left: 50px;
}

.pb-50 {
  padding-bottom: 50px;
}

.m-50 {
  margin: 50px;
}

.mt-50 {
  margin-top: 50px;
}

.mb-50 {
  margin-bottom: 50px;
}

.br-10 {
  border-radius: 10px;
}

.w-55 {
  width: calc(55% - 18px);
}
@media screen and (max-width: 750px) {
  .w-55 {
    width: calc(96% - 18px);
  }
}

.p-55 {
  padding: 55px;
}

.pt-55 {
  padding-top: 55px;
}

.pr-55 {
  padding-right: 55px;
}

.pl-55 {
  padding-left: 55px;
}

.pb-55 {
  padding-bottom: 55px;
}

.m-55 {
  margin: 55px;
}

.mt-55 {
  margin-top: 55px;
}

.mb-55 {
  margin-bottom: 55px;
}

.br-11 {
  border-radius: 11px;
}

.w-60 {
  width: calc(60% - 18px);
}
@media screen and (max-width: 750px) {
  .w-60 {
    width: calc(96% - 18px);
  }
}

.p-60 {
  padding: 60px;
}

.pt-60 {
  padding-top: 60px;
}

.pr-60 {
  padding-right: 60px;
}

.pl-60 {
  padding-left: 60px;
}

.pb-60 {
  padding-bottom: 60px;
}

.m-60 {
  margin: 60px;
}

.mt-60 {
  margin-top: 60px;
}

.mb-60 {
  margin-bottom: 60px;
}

.br-12 {
  border-radius: 12px;
}

.w-65 {
  width: calc(65% - 18px);
}
@media screen and (max-width: 750px) {
  .w-65 {
    width: calc(96% - 18px);
  }
}

.p-65 {
  padding: 65px;
}

.pt-65 {
  padding-top: 65px;
}

.pr-65 {
  padding-right: 65px;
}

.pl-65 {
  padding-left: 65px;
}

.pb-65 {
  padding-bottom: 65px;
}

.m-65 {
  margin: 65px;
}

.mt-65 {
  margin-top: 65px;
}

.mb-65 {
  margin-bottom: 65px;
}

.br-13 {
  border-radius: 13px;
}

.w-70 {
  width: calc(70% - 18px);
}
@media screen and (max-width: 750px) {
  .w-70 {
    width: calc(96% - 18px);
  }
}

.p-70 {
  padding: 70px;
}

.pt-70 {
  padding-top: 70px;
}

.pr-70 {
  padding-right: 70px;
}

.pl-70 {
  padding-left: 70px;
}

.pb-70 {
  padding-bottom: 70px;
}

.m-70 {
  margin: 70px;
}

.mt-70 {
  margin-top: 70px;
}

.mb-70 {
  margin-bottom: 70px;
}

.br-14 {
  border-radius: 14px;
}

.w-75 {
  width: calc(75% - 18px);
}
@media screen and (max-width: 750px) {
  .w-75 {
    width: calc(96% - 18px);
  }
}

.p-75 {
  padding: 75px;
}

.pt-75 {
  padding-top: 75px;
}

.pr-75 {
  padding-right: 75px;
}

.pl-75 {
  padding-left: 75px;
}

.pb-75 {
  padding-bottom: 75px;
}

.m-75 {
  margin: 75px;
}

.mt-75 {
  margin-top: 75px;
}

.mb-75 {
  margin-bottom: 75px;
}

.br-15 {
  border-radius: 15px;
}

.w-80 {
  width: calc(80% - 18px);
}
@media screen and (max-width: 750px) {
  .w-80 {
    width: calc(96% - 18px);
  }
}

.p-80 {
  padding: 80px;
}

.pt-80 {
  padding-top: 80px;
}

.pr-80 {
  padding-right: 80px;
}

.pl-80 {
  padding-left: 80px;
}

.pb-80 {
  padding-bottom: 80px;
}

.m-80 {
  margin: 80px;
}

.mt-80 {
  margin-top: 80px;
}

.mb-80 {
  margin-bottom: 80px;
}

.br-16 {
  border-radius: 16px;
}

.w-85 {
  width: calc(85% - 18px);
}
@media screen and (max-width: 750px) {
  .w-85 {
    width: calc(96% - 18px);
  }
}

.p-85 {
  padding: 85px;
}

.pt-85 {
  padding-top: 85px;
}

.pr-85 {
  padding-right: 85px;
}

.pl-85 {
  padding-left: 85px;
}

.pb-85 {
  padding-bottom: 85px;
}

.m-85 {
  margin: 85px;
}

.mt-85 {
  margin-top: 85px;
}

.mb-85 {
  margin-bottom: 85px;
}

.br-17 {
  border-radius: 17px;
}

.w-90 {
  width: calc(90% - 18px);
}
@media screen and (max-width: 750px) {
  .w-90 {
    width: calc(96% - 18px);
  }
}

.p-90 {
  padding: 90px;
}

.pt-90 {
  padding-top: 90px;
}

.pr-90 {
  padding-right: 90px;
}

.pl-90 {
  padding-left: 90px;
}

.pb-90 {
  padding-bottom: 90px;
}

.m-90 {
  margin: 90px;
}

.mt-90 {
  margin-top: 90px;
}

.mb-90 {
  margin-bottom: 90px;
}

.br-18 {
  border-radius: 18px;
}

.w-95 {
  width: calc(95% - 18px);
}
@media screen and (max-width: 750px) {
  .w-95 {
    width: calc(96% - 18px);
  }
}

.p-95 {
  padding: 95px;
}

.pt-95 {
  padding-top: 95px;
}

.pr-95 {
  padding-right: 95px;
}

.pl-95 {
  padding-left: 95px;
}

.pb-95 {
  padding-bottom: 95px;
}

.m-95 {
  margin: 95px;
}

.mt-95 {
  margin-top: 95px;
}

.mb-95 {
  margin-bottom: 95px;
}

.br-19 {
  border-radius: 19px;
}

.w-100 {
  width: calc(100% - 18px);
}
@media screen and (max-width: 750px) {
  .w-100 {
    width: calc(96% - 18px);
  }
}

.p-100 {
  padding: 100px;
}

.pt-100 {
  padding-top: 100px;
}

.pr-100 {
  padding-right: 100px;
}

.pl-100 {
  padding-left: 100px;
}

.pb-100 {
  padding-bottom: 100px;
}

.m-100 {
  margin: 100px;
}

.mt-100 {
  margin-top: 100px;
}

.mb-100 {
  margin-bottom: 100px;
}

.br-20 {
  border-radius: 20px;
}

@media screen and (max-width: 750px) {
  .br-sm {
    flex-direction: column;
  }
  .br-sm > * {
    width: 100%;
  }
}

main {
  position: absolute;
  width: 100%;
  top: 100vh;
  min-height: 100vh;
  z-index: 2;
  padding-top: 1px;
  padding-bottom: 21px;
  background-color: #f9f9f9;
}
@media screen and (max-width: 750px) {
  main {
    top: 63vh;
  }
}

.contact-page .cont span, .contact-page .cont a {
  font-size: 1.2rem;
  margin-left: 12px;
}

.position-scroll-marker {
  position: absolute;
  top: -70px;
  height: 0;
  margin: 0;
  padding: 0;
  opacity: 0;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.accent-blue {
  color: #1dace3;
}
.accent-blue.reverse {
  background-color: #1dace3;
  color: #fff;
}

.accent-circle {
  border-top: 3px solid #fff;
  margin-top: 10px;
  height: 10px;
  position: relative;
}
.accent-circle > span {
  display: block;
  height: 60px;
  width: 60px;
  border: 3px solid #fff;
  border-radius: 50%;
  margin: 0 auto;
  margin-top: -50px;
  background-color: #1dace3;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  box-shadow: 0 0 0 3px #1dace3;
}

.s-top-1 {
  border-top: 1px solid #dfdfdf;
}