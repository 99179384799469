.menu-bar {
  z-index: 3;
  width: 100%;
  height: 60px;
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  box-shadow: 0 0 13px rgba(0, 0, 0, 0.3);
}
.menu-bar .logo {
  padding-top: 14px;
  padding-left: 20px;
  width: 10%;
  min-width: 165px;
}
.menu-bar .logo svg {
  height: 100%;
  width: 100%;
}
.menu-bar .nav {
  width: 85%;
  display: flex;
  justify-content: space-evenly;
}
.menu-bar .nav a {
  display: block;
  width: 15%;
  text-align: center;
  padding-top: 22px;
}
.menu-bar .nav a.current-page {
  border-bottom: 4px solid #db0f56;
}
@media screen and (max-width: 750px) {
  .menu-bar .nav {
    display: none;
  }
}
.menu-bar .iconTainer {
  padding-right: 0px;
}
.menu-bar .icon {
  background-color: #db0f56;
  width: 100%;
  height: 60px;
  width: 60px;
  text-align: center;
  align-self: flex-end;
  display: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.4s;
}
@media screen and (max-width: 750px) {
  .menu-bar .icon {
    display: flex;
  }
}
.menu-bar .icon:hover {
  background-color: gray;
}