.fade-enter {
    opacity: 0.01;
  }
  
.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 100ms ease-in;
}

.fade-leave {
    opacity: 1;
}

.fade-leave.fade-leave-active {
    opacity: 0.01;
    transition: opacity 100ms ease-in;
}