@import './variables/colors.scss';
@import './variables/settings.scss';
@import './variables/mixins.scss';
@import './text.scss';
@import './forms.scss';

body{
    font-family: $font-body;
    font-size: 16px;
}

ul{
    list-style-type: none;
    list-style-position: inside;
    margin: 0;
    padding: 0;
}

.content{
    width: 95%;
    max-width: 1200px; 
    min-width: 250px;
    margin: 0 auto; 
}

.row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.j-start {
    justify-content: flex-start;
}

.a-center {
    align-items: center;
}

.p-relative {
    position: relative;
}

@for $i from 0 through 20 {
    $num: $i * 5;
    .w-#{$num} {
        width: calc(#{$num}% - #{$row-space});
        @media screen and (max-width: $break-small) {
            width: calc(96% - #{$row-space});
        }
    }
    // Padding
    .p-#{$num} {
        padding: #{$num}px;
    }
    .pt-#{$num} {
        padding-top: #{$num}px;
    }
    .pr-#{$num} {
        padding-right: #{$num}px;
    }
    .pl-#{$num} {
        padding-left: #{$num}px;
    }
    .pb-#{$num} {
        padding-bottom: #{$num}px;
    }
    // Margin
    .m-#{$num} {
        margin: #{$num}px;
    }
    .mt-#{$num} {
        margin-top: #{$num}px;
    }
    .mb-#{$num} {
        margin-bottom: #{$num}px;
    }
    // Border radius
    .br-#{$i} {
        border-radius: #{$i}px;
    }
}

.br-sm {
    @media screen and (max-width: $break-small) {
        flex-direction: column;
            & > * {
                width: 100%;
            }
        }
    }

main{ 
    position: absolute; 
    width: 100%;
    top: 100vh;
    min-height: 100vh;
    z-index: 2; 
    padding-top: 1px;
    padding-bottom: 21px;
    background-color: $main-body-color;
    @media screen and (max-width: $break-small) {
        top: 63vh;
    }
}

.contact-page .cont span, .contact-page .cont a{ 
    font-size: 1.2rem;
    margin-left: 12px;
}

// Hidden element for scrolling
.position-scroll-marker {
    position: absolute;
    top: -70px;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
}

// utility
.center {
    text-align: center;
}
.right {
    text-align: right;
}

.accent-blue {
    color: $accent-blue;
    &.reverse {
        background-color: $accent-blue;
        color: #fff;
    }
}

.accent-circle {
    border-top: 3px solid #fff;
    margin-top: 10px;
    height: 10px;
    position: relative;
    & > span {
        display: block;
        height: 60px;
        width: 60px;
        border: 3px solid #fff;
        border-radius: 50%;
        margin: 0 auto;
        margin-top: -50px;
        background-color: $accent-blue;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 3rem;
        font-weight: bold;
        font-family: $font-header;
        box-shadow: 0 0 0 3px $accent-blue;
    }
}

.s-top-1 {
    border-top: 1px solid rgb(223, 223, 223);
}