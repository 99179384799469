button{
    border: none;
    background-color: transparent;
    font-weight: bold;
    cursor: pointer;
    font-family: $font-header; 
}
.button-form{
    background-color: $accent-pink;
    padding: 10px 20px;
    color: #fff; 
    border-radius: 4px;   
    letter-spacing: .1rem;
    margin-top: 10px;
    &:hover{
        background-color: #4b4b4b;
    }
}

label{ 
    font-weight: bold;
    color: #4b4b4b;
    font-size: 1.02rem;
}
input[type=text], textarea{
    padding: 15px 10px;
    display: block;
    margin: 10px 0 23px 0;
    width: calc(100% - 20px);
    border: none;
    background-color: $field-color;
    box-shadow: 0 0 0 2px $accent-blue;
    transition: all .3s;
    border-radius: 5px;
    color: rgb(48, 48, 48);
    transition: all .3s;
    &::placeholder {
        color: rgb(160, 160, 160);
    }
    &:focus{
        padding: 15px 15px;
        width: calc(100% - 30px);
        box-shadow: 0 0 0px 1px $accent-pink;
        border-color: $accent-pink;
        background-color: rgb(243, 243, 243);
    }
}