.images-container {
  margin-top: 35px;
  width: 100%;
}
.images-container .image-item {
  float: left;
  background-size: 150%;
  background-position: 10% 10%;
  transition: background-position 2s, margin 0.3s, border-radius 1s;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}
.images-container .image-item .image-cover {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  transition: background-color 1s;
  font-weight: bold;
  color: #fff;
  transition: border-radius 1s;
}
.images-container .image-item .image-cover span {
  background-color: #fff;
  color: #000;
  padding: 2px 4px;
  border-radius: 2px;
  opacity: 0;
  transition: opacity 0.5s;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
.images-container .image-item .image-cover P {
  text-shadow: 1px 1px 3px black;
  letter-spacing: 0.12rem;
  text-align: center;
  margin-top: 35px;
  transition: all 0.5s;
  padding: 5px 10px;
  max-width: 60%;
  background-color: #1dace3;
}
.images-container .image-item:hover {
  background-position: 0% 0%;
}
.images-container .image-item:hover .image-cover span {
  display: block;
  opacity: 1;
}
.images-container .image-item:hover .image-cover p {
  margin-top: 0;
  background-color: yellow;
  text-shadow: none;
  color: #000;
  box-shadow: 10px 10px 0 black;
}
.images-container.mobile-container .image-item {
  width: 98%;
  float: none;
  margin: 0 auto;
  margin-bottom: 10px;
  border-radius: 4px;
}
.images-container.mobile-container .image-item .image-cover {
  border-radius: 5px;
}
.images-container .clear-fix {
  width: 100%;
  height: 0;
  clear: both;
}