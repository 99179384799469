h1, h2{
    font-family: $font-header;
    font-weight: bold;
    font-size: 2rem;
    color: $accent-pink;
}
a{
    text-decoration: none;
    color: inherit;
}
.t-small {
    font-size: .9rem;
}
.t-large {
    font-size: 1.2rem;
}
.t-light {
    color: #646464;
}
.t-space {
    line-height: 1.9rem;
}
.t-blue {
    color: $accent-blue;
}