@import './variables/colors.scss';
@import './variables/settings.scss';

header{
    height: 100vh; 
    width: 100%;
    background-image: url("../images/ui/city.jpg");
    background-size: cover;
    background-repeat: repeat;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1; 
    .top-bar{ 
        display: flex;    
        height: 100%;  
        justify-content: space-between;
    }
    section{
        padding: 20px 0; 
        background-color: rgba(255, 255, 255, 0.6); 
        @media screen and (max-width: $break-small) {
            display: none;
          }
    } 
    .logo{  
        width: 30%;
        align-self: center;
        justify-self: center;
        svg{ 
            height: 50px;
            width: 100%;
            margin-left: 20px;
        }
    }
    ul, nav{  
        justify-self: right;
        display: flex; 
        font-size: 1.3rem;
    }
    ul{
        font-size: 1.1rem;
        svg{
            position: relative;
            width: 27px;
        }
    }
    nav{ 
        text-align: center;
        align-content: center; 
        justify-content: center;
        margin-top: 32px;
        a{
            padding: 5px 30px 3px 30px;
            color: $muted-gray;
            &:not(:first-child){
                border-left: 1px solid $muted-gray; 
            }
            &:hover{ 
                color: black;
            }  
        }
    }
    .opener{
        display: flex;
        flex: 1; 
        flex-direction: column;
        justify-content: center; 
        text-align: center;
        margin-top: 450px;
        transition: all .56s .1s;
        opacity: 0;
        &.item-mounted {
            margin-top: 0;
            opacity: 1;
        }
        & *{ 
            margin: 0;
        }
        & p:first-child{
            background-color: black; 
            color: #fff;
            font-size: 1.5em;
            margin-bottom: 5px; 
            margin-left: 65px;
            padding: 8px;
            align-self: flex-start; 
            position: relative;
            &:after{
                content: "";
                position: absolute; 
                top: 0;
                left: -30px;
                border-style: solid;
                border-width: 43px 0px 0 30px;
                border-color: black transparent transparent transparent;
            }
            &:before{
                content: "";
                position: absolute; 
                top: .6px;
                right: -30px;
                border-style: solid;
                border-width: 0 30px 43px 0;
                border-color: transparent transparent black transparent;
                @supports (-ms-ime-align: auto) {
                    &{ border-width: 0 30px 44px 0; }
                }
                @-moz-document url-prefix() {
                    &{ border-width: 0 30px 44px 0; }
                }
            }
            
        }
        a{  
            padding: 16px 15px;
            margin: 0 auto;
            border-radius: 2px;
            margin-top: 40px;
            text-align: center;
            background-color: $accent-blue;
            color: #fff;
            display: flex;
            align-items: center;
            transition: all .3s;
            span{
                font-size: 1.7rem;
                line-height: 6px; 
                font-weight: normal;
                margin: 5px;
                font-weight: bold;
            }
            &:hover{
                background-color: $accent-yellow;
                box-shadow: 13px 13px 0px black;
                color: black;
            }
        }
    }
    .lrg {
        font-size: 6rem;
        font-family: $font-header;
        font-weight: bold;
        text-align: center;
        color: $accent-pink;
        align-self: center;
        line-height: 1.2em;
        width: 100%;
    }  
    @media screen and (max-width: $break-small) {
        .lrg{
            font-size: 3rem; 
            margin-top: 14px;
            line-height: 4rem;
        }
        .opener p:first-child{ 
            margin: 90px auto 8px auto;
            &:before{
                border-width: 43px 30px 30px 0px;
                border-color:  black transparent transparent transparent;
            } 
        }

        &{
            height: 63vh;
            padding-bottom: 0;
        }
        .opener a{ 
            font-size: 1.2rem; 
            margin-top: 13px;
        }
    }
    @media screen and (max-width: 500px) {
        .lrg{font-size: 2.6rem; line-height: 3.7rem; }
    }
}
 
