@import './variables/colors.scss';
@import './variables/settings.scss';

.menu-bar{
    z-index: 3;
    width: 100%;
    height: 60px;
    background-color: rgb(255, 255, 255);
    position: fixed;
    top: 0;
    left: 0;
    display: flex; 
    box-shadow: 0 0 13px rgba(0, 0, 0, .3);
    .logo{
        padding-top: 14px;
        padding-left: 20px;
        width: 10%;
        min-width: 165px; 
        svg{
            height: 100%;
            width: 100%;
        }
    }
    .nav{
        width: 85%;
        display: flex;
        justify-content: space-evenly; 
        a{
            display: block; 
            width: 15%;
            text-align: center;
            padding-top: 22px;
            &.current-page{ 
                border-bottom: 4px solid $accent-pink;
            }
        }
        @media screen and (max-width: $break-small) {
            display: none;
          }
    }
    .iconTainer{ 
        padding-right: 0px;
    }
    .icon{
        background-color: $accent-pink;
        width: 100%;
        height: 60px;
        width: 60px;
        text-align: center;
        align-self: flex-end;
        display: none;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: background-color .4s;
        @media screen and (max-width: $break-small) {
            display: flex;
        }
        &:hover {
            background-color: gray;
        }
    }
}