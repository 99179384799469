@mixin accent-button {
    margin-top: 40px; 
    background-color: $accent-pink;
    color: #fff;
    padding: 8px 20px; 
    right: 0;
    bottom: 0;
    text-align: center; 
    border-radius: 5px;
    margin-left: auto;
    &:hover{
        background-color: gray;
    }
}