@import './variables/colors';
@import './variables/settings.scss';

@mixin sb-toggle-btn {
    font-size: 16px;
    position: absolute;
    padding: 0;
    width: 60px;
    height: 60px;
    padding-top: 2px;
    background-color: $accent-pink;
    color: #fff;
    transition: background-color .3s;
    &:hover{
        color: #fff;
        background-color: rgb(65, 65, 65);
    }
}

.side-bar{
    background-color: #fff;
    box-shadow: 0 0 6px rgba(0, 0, 0, .4);
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 450px;
    z-index: 4;
    & .logo{ 
        height: 60px;
        padding: 0px 20px 0px 80px;
        margin-bottom: 20px;
        border-bottom: 4px solid $accent-pink;
        svg{
            width: 60%; 
            margin-top: -7px;
        }
    }
    & button{
        @include sb-toggle-btn;
    } 
    & .cont{
        border-top: 4px solid $accent-pink;
        padding-top: 21px;
    }
    & .cont span, & .cont a{
        font-size: 1.2em;
        margin-left: 10px;
    }
    & .nav a{
        display: block; 
        padding: 22px 25px;
        font-size: 1.2rem;
        transition: all .1s;
        &:hover{
            border-left: 8px solid $accent-pink;
            padding: 22px 0 22px 35px;
            background-color: $accent-pink-light;
            color: $accent-pink;
        }
        &.current-page{
            border-left: 4px solid $accent-pink;
        }
    } 
    @media screen and (max-width: $break-small) {
        max-width: 100%; 
      }
}