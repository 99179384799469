.my-list-card {
  width: 100%;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  padding: 0 0 15px 0;
  margin-bottom: 21px;
  background-color: #fff;
  border-radius: 4px;
}
.my-list-card li {
  list-style-type: disc;
  list-style-position: outside;
  line-height: 1.7rem;
  margin-left: 55px;
  width: calc(96% - 55px);
  padding-bottom: 6px;
}
.my-list-card li.card-title {
  list-style-type: none;
  font-size: 1.7rem;
  font-weight: bold;
  margin-left: 0;
  margin-bottom: 18px;
  padding: 15px 0 15px 15px;
  border-bottom: 3px solid #1dace3;
  color: #363636;
  width: calc(100% - 15px);
}
.my-list-card li.list-item {
  list-style-type: none;
  margin-left: 30px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 5px;
  color: #db0f56;
  font-size: 1.1rem;
}