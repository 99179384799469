@import './variables/colors.scss';
@import './variables/settings.scss';

.popUpContainer {
    height: 100vh;
    width: 100%;
    position: fixed;
    z-index: 5;
    display: block;
};
.popUpBg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: .9;
    z-index: 6
};
.popUpContent {
    z-index: 7;
    width: 99%;
    height: calc(99vh - 36px);
    position: relative;
    margin: 22px auto 0 auto
};
.popUpImage {
    margin: 20px auto;
    display: block;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-position: top center;
};
.popUpControls {
    display: flex;
    justify-content: space-between;
    width: 98%;
    margin: 0 auto;
    padding: 6px;
    font-size: 1.1rem;
    color: #fff;
    font-weight: bold
};
.closeBtn {
    color: #fff;
    border-radius: 5px; 
    padding: 5px 13px;
    position: relative;
    right: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $accent-pink;
    transition: background-color .3s;
    &:hover{
        background-color: gray;
    }
}